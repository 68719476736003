<template>
  <modal
    class-name="w-full md:w-700px pt-10 px-10"
    ref="modal"
    @close="close"
  >
    <template v-if="loan">
      <!-- User -->
      <section class="mb-10">
        <div class="flex flex-row items-center justify-between mb-1">
          <div class="flex flex-row items-center">
            <h2 class="text-2xl font-bold mr-2">
              {{ staff.name | sentenceCase }} {{ staff.last_name | sentenceCase }}
            </h2>
          </div>
        </div>

        <div class="text-sm text-gray-500 mb-5">
          {{ position | sentenceCase }}
        </div>

        <div class="flex flex-wrap gap-2">
          <badge-loan-status :loan="loan" />
          <router-link
            v-if="isPending"
            :to="{ name: 'staff-view', params: { staffId: staff.id }, query: { tab: 'loanbot' } }"
            target="_blank"
            class="btn btn-blue"
          >
            <ion-icon name="eye-outline" class="text-xl"></ion-icon>
            <span class="ml-2">Review Application</span>
          </router-link>

          <router-link
            v-if="loan.offer_letter"
            :to="{ name: 'offer-letter', params: { loanId: loan.id } }"
            target="_blank"
            class="btn btn-blue"
          >
            <ion-icon name="document-text-outline" class="text-xl"></ion-icon>
            <span class="ml-2">Offer Letter</span>
          </router-link>
        </div>
      </section>
      <!-- User -->

      <!-- Summary -->
      <section class="mb-10">
        <div class="mb-2 text-gray-500">Loan Summary:</div>

        <div class="grid grid-cols-1 sm:grid-cols-2 border border-blue-200">
          <div class="col-span-1 p-4">
            <div class="opacity-75 text-sm font-medium mb-1">
              Date Requested
            </div>
            <div
              class="text-gray-500 text-sm font-light flex flex-row items-center"
            >
              <ion-icon name="barcode-outline" class="text-lg mr-2"></ion-icon>
              {{ createdAt | dateFormat }}
            </div>
          </div>
          <div
            class="col-span-1 p-4 border-t sm:border-t-0 sm:border-l border-blue-200"
          >
            <div class="opacity-75 text-sm font-medium mb-1">
              {{ finalDateLabel }}
            </div>
            <div
              class="text-gray-500 text-sm font-light flex flex-row items-center"
            >
              <ion-icon name="briefcase-outline" class="text-lg mr-2"></ion-icon>
              {{ finalDate | dateFormat }}
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 border border-blue-200 -mt-px">
          <div class="col-span-1 p-4">
            <div class="opacity-75 text-sm font-medium mb-1">
              Loan ID
            </div>
            <div
              class="text-gray-500 text-sm font-light flex flex-row items-center"
            >
              <ion-icon name="barcode-outline" class="text-lg mr-2"></ion-icon>
              {{ loan.id }}
            </div>
          </div>
          <div
            class="col-span-1 p-4 border-t sm:border-t-0 sm:border-l border-blue-200"
          >
            <div class="opacity-75 text-sm font-medium mb-1">
              Loan Amount
            </div>
            <div
              class="text-gray-500 text-sm font-light flex flex-row items-center"
            >
              <ion-icon name="briefcase-outline" class="text-lg mr-2"></ion-icon>
              {{ loanAmount | currency }}
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 border border-blue-200 -mt-px">
          <div class="col-span-1 p-4">
            <div class="opacity-75 text-sm font-medium mb-1">
              Loan Tenure
            </div>
            <div
              class="text-gray-500 text-sm font-light flex flex-row items-center"
            >
              <ion-icon name="calendar-outline" class="text-lg mr-2"></ion-icon>
              {{ loanTenure }} {{ 'month' | toPlural(loanTenure) }}
            </div>
          </div>
          <div
            class="col-span-1 p-4 border-t sm:border-t-0 sm:border-l border-blue-200"
          >
            <div class="opacity-75 text-sm font-medium mb-1">
              Interest Rate
            </div>
            <div
              class="text-gray-500 text-sm font-light flex flex-row items-center"
            >
              <ion-icon name="calculator-outline" class="text-lg mr-2"></ion-icon>
              {{ interestRate }} %
            </div>
          </div>
        </div>

        <div
          class="grid grid-cols-1 sm:grid-cols-2 border border-blue-200 -mt-px"
        >
          <div class="col-span-1 p-4">
            <div class="opacity-75 text-sm font-medium mb-1">
              Employment Status
            </div>
            <div
              class="text-gray-500 text-sm font-light flex flex-row items-center"
            >
              <ion-icon name="briefcase-outline" class="text-lg mr-2"></ion-icon>
              {{ employmentStatus | sentenceCase }}
            </div>
          </div>
          <div
            class="col-span-1 p-4 border-t sm:border-t-0 sm:border-l border-blue-200"
          >
            <div class="opacity-75 text-sm font-medium mb-1">
              Monthly Salary / Income
            </div>
            <div
              class="text-gray-500 text-sm font-light flex flex-row items-center"
            >
              <ion-icon name="wallet-outline" class="text-lg mr-2"></ion-icon>
              {{ salary | currency }}
            </div>
          </div>
        </div>

        <div
          class="grid grid-cols-1 sm:grid-cols-2 border border-blue-200 -mt-px"
        >
          <div class="col-span-1 p-4">
            <div class="opacity-75 text-sm font-medium mb-1">
              Standard Loan Limit
            </div>
            <div
              class="text-gray-500 text-sm font-light flex flex-row items-center"
            >
              <ion-icon name="shield-outline" class="text-lg mr-2"></ion-icon>
              {{ standardLoanLimit | currency }}
            </div>
          </div>
          <div
            class="col-span-1 p-4 border-t sm:border-t-0 sm:border-l border-blue-200"
          >
            <div class="opacity-75 text-sm font-medium mb-1">
              Source
            </div>
            <div
              class="text-gray-500 text-sm font-light flex flex-row items-center"
            >
              <ion-icon name="arrow-redo-outline" class="text-lg mr-2"></ion-icon>
              {{ source | sentenceCase }}
            </div>
          </div>
        </div>
      </section>
      <!-- Summary -->

      <!-- Loan History -->
      <section class="mb-10">
        <div class="mb-2 text-gray-500">Loan History:</div>
        <div class="border border-blue-200">
          <datatable
            :data="loans.data"
            :columns="loans.columns"
            :limit="5"
            :loading="loans.loading"
            :fillable="false"
          >
            <template v-slot:td-3="{ item: { row } }">
              <badge-loan-status :loan="row" />
            </template>
          </datatable>
        </div>
      </section>
      <!-- Loan History -->
    </template>
    <div>
      <button type="button" class="btn btn-blue" @click="close">
        Close
      </button>
    </div>
  </modal>
</template>

<script>
  export default {
    props: {
      loan: {
        type: Object,
      },
    },
    data() {
      return {
        closeTimeout: null,
        loans: this.$options.resource([], {
          columns: [
            {
              align: 'right',
              name: "loan_amount",
              th: "Loan Amount",
              render: ({ loan_amount: loanAmount }) => `₦ ${this.$options.filters.currency(loanAmount)}`,
            },
            {
              align: 'right',
              name: "interest_rate",
              th: "Interest Rate",
              render: ({ interest_rate: interestRate }) => `${interestRate} %`,
            },
            {
              name: "status",
              th: "Status",
            },
            {
              name: "created_at",
              th: "Requested Date",
              render: (loan, created_at) =>
                this.$options.filters.dateFormat(created_at),
            },
          ],
        }),
      }
    },
    computed: {
      createdAt() {
        return this.loan?.created_at;
      },
      employmentStatus() {
        return this.staff?.profile?.employment_status;
      },
      finalDateLabel () {
        switch (this.loan?.status) {
          case 'paid':
            return 'Date Paid Off';
          case 'disbursed':
            return 'Date Disbursed';
          case 'rejected':
            return 'Date Rejected';
          case 'accepted':
            return 'Date Accepted';
          case 'declined':
            return 'Date Declined';
          case 'approved':
          default:
            return 'Pending Approval';
        }
      },
      finalDate () {
        switch (this.loan?.status) {
          case 'paid':
            return this.loan?.updated_at;
          case 'disbursed':
            return this.loan?.disbursed_at;
          case 'rejected':
            return this.loan?.rejected_at;
          case 'accepted':
            return this.loan?.accepted_at;
          case 'declined':
          case 'approved':
            return this.loan?.decided_at;
          default:
            return null;
        }
      },
      interestRate() {
        return this.loan?.interest_rate;
      },
      isPending() {
        return this.loan?.status == 'pending';
      },
      loanAmount() {
        return this.loan?.loan_amount;
      },
      loanTenure() {
        return this.loan?.tenure;
      },
      position() {
        return this.staff?.profile?.position || 'CredPal User';
      },
      salary() {
        return this.staff?.profile?.salary || 0;
      },
      standardLoanLimit() {
        return this.staff?.profile?.standard_loan_limit || 0;
      },
      staff() {
        return this.loan?.user;
      },
      source() {
        return this.loan?.source || this.staff?.source;
      },
    },
    watch: {
      staff(staff) {
        if (staff) {
          this.getUserLoans();
        }
      },
    },
    methods: {
      close() {
        if (this.$refs.modal.isOpen) {
          this.$refs.modal.close();
        }

        this.closeTimeout = setTimeout(() => {
          this.$emit('close');
        }, 1000);
      },
      open() {
        clearTimeout(this.closeTimeout);
        this.$refs.modal.open();
        this.$emit('open')
      },

      async getUserLoans() {
        this.loans.loading = true;
        await this.$get({
          url: `${this.$baseurl}/staff/loans/${this.loan.user.id}?exclude=${this.loan.id}`,
          headers: this.headers,
          success: (response) => {
            this.loans.data = response.data.loans;
          },
        });
        this.loans.loading = false;
      },
    },
  }
</script>
